import API from '../../configs/apiConfig'

export async function completeRegistration(data) {
  console.log("data to send")
  console.log(data.registration)
  return API.post('auth/signup', data.registration)
}

export async function validateEmail(data) {
  return API.post('auth/checkEmail', data.finalData)
}

export async function validatePhone(data) {
  return API.post('auth/checkNumber', data.finalData)
}

export async function login(data) {
  return API.post('auth/signin', data.finalData)
}

export async function saveFailedLogin(data) {
  return API.post('saveFailedLogin', data)
}

export async function sendOTP(data) {
  return API.post('sendEmailOtp', data)
}

export async function getUser(data) {
  return API.get(`getUser`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function editProfile(data) {
  return API.post('updateProfile', data.finalData,{
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function logout(data) {
  return API.get(`signout`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function forgotPassword(data) {
  return API.get(`forgotPassword/${data.userDetails}`)
}

export async function resetPassword(data) {
  return API.post(`resetPassword`,data)
}

export async function getAllRating(data) {
  return API.get(`getAllRatings`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function giveRating(data) {
  return API.post('saveRating', data.finalData,{
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}


export async function updateTouchPointScore(data) {
  return API.get(`updateTouchPointScore?touchPointId=${data.touchPointId}&elementId=${data.elementId}&touchPointName=${data.touchPointName}`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function validateSession(data) {
  return API.get('validateSession', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}
