import { all, takeEvery, call, put, select } from 'redux-saga/effects'
import { message } from 'antd'
import { getSavedData,getRoles,writeData,deleteSection,uploadFile,getBackendAgenda } from '../../../services/organizer/eventSetup'
import actions from './actions'

export function* GET_INIT() {
  try {
    const setupData = yield call(getSavedData)
    console.log(setupData)
    if (Object.keys(setupData.data.realms).length > 0) {
      const final = setupData.data.realms
      yield put({
        type: actions.SET_STATE,
        payload: {
          realms: final,
          currentScene: Object.keys(final)[0],
        },
      })
    }

    // const roles = yield call(getRoles)
    // if (roles.status === 200) {
    //   yield put({
    //     type: actions.SET_STATE,
    //     payload: {
    //       roles: roles.data,
    //     },
    //   })
    // }

    // const theAgendas = yield call(getBackendAgenda)
    // if (Object.keys(theAgendas.data).length > 0) {
    //   yield put({
    //     type: actions.SET_STATE,
    //     payload: {
    //       agenda: theAgendas.data,
    //     },
    //   })
    // }
  } catch (err) {
    console.log(err)
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SAVE_DATA() {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: {
        saveEventLoader: true
      },
    })
    const state = yield select()
    const { eventSetup: {realms,order }} = state
    const result = yield call(writeData, { load: {realms,order} })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          saveEventLoader: false
        },
      })
      message.success('Realms are updated successfully.')
    } else {
      message.error('Some Error Updating the DB')
    }
  } catch (err) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        saveEventLoader: false
      },
    })
    message.error('Some Error Updating the Scene. Try again by changing the values')
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* DELETE_CLICKSECTION(data) {
  try {
    const result = yield call(deleteSection, data.payload.clickSection)
    console.log(result)
    if (result.status === 200) {
      yield put({
        type: actions.SET_SCENE,
        payload: {
          key: 'clickSections',
          actualload: data.payload.allClickSections,
          currentScene: data.payload.currentScene,
        },
      })
      message.success('Section Deleted Successfully')
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* UPLOAD_FILE(data) {
  try {
    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        console.log(percentCompleted)
        data.payload.onProgress({ percent: percentCompleted })
      },
    }

    const result = yield call(uploadFile, { file: data.payload.file, config })
    if (result.status === 200) {
      console.log(result)
      yield put({
        type: actions.SET_SCENE,
        payload: {
          key: data.payload.associatedKey,
          actualload: result.data.fileUrl,
          currentScene: data.payload.currentScene,
        },
      })
      message.success('File Uploaded Successfully')
    } else {
      data.payload.onError({ event: result.statusText })
      message.error('Error occured in uploading the Image.')
    }
  } catch (err) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        imageUploadLoading: false
      },
    })
    console.log(err)
    data.payload.onError({ event: err.response.data.message })
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_INIT, GET_INIT),
    takeEvery(actions.SAVE_DATA, SAVE_DATA),
    takeEvery(actions.DELETE_CLICKSECTION, DELETE_CLICKSECTION),
    takeEvery(actions.UPLOAD_FILE, UPLOAD_FILE),
  ])
}
