import actions from './actions'
import { UnityContext } from "react-unity-webgl";

const initialState = {
  sendMessage: null,
  isLoaded: false
}

export default function virtualuserReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}