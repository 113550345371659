import React, { useState } from "react";
import {Row,Col,Card} from 'antd'
import Iframe from "react-iframe";
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/altRealm/actions';

export default function ArcadeGames() {
  const requiredState = useSelector(({altRealm})=>({altRealm}))
  const {altRealm: {arcadeGameStep,arcadeGameURL}} = requiredState
  const dispatch = useDispatch();

  const {altRealm:{isInteractionDrawerVisible}} = requiredState
  const gameObject = {
    1: {
      gameIcon: "https://wiz365.s3.amazonaws.com/wiztalesgames/Mr.+Long+Leg.jpg",
      gamename: "Mr. Long Leg",
      gamedesc:
        "Experience Mr. long legs a hypercasual fun game where you need to control Mr. Long Leg",
      game_instn:
        "Tap and Hold To Make The Legs Longer / Release Your Finger To Make The Leg Shorter",
      gameurl: "https://wiz-long-leg-leaderboard-new.netlify.app",
      pointing: "1",
    },
    2: {
      gameIcon: "https://wiz365.s3.amazonaws.com/wiztalesgames/Brain+Games.jpg",
      gamename: "Brain Game",
      gamedesc:
        "Brain Games is a series of challenges, riddles and puzzles. Test your logical thinking, accuracy, memory and creativity and have fun.",
      game_instn: "Solve the puzzles to move forward",
      gameurl: "https://wiz-brain-games-leaderboard.netlify.app/",
      pointing: "5",
    },
    3: {
      gameIcon: "https://wiz365.s3.amazonaws.com/wiztalesgames/Math+Games.jpg",
      gamename: "Math Game",
      gamedesc:
        "This is a memory game in which the player must match the 16 cards according math operations (addition, subtraction, multiplication, division)",
      game_instn: "Match the cards to move forward",
      gameurl: "https://wiz-math-memory-leaderboard-new.netlify.app/",
      pointing: "10",
    },
  };

  const openGame = (theURL) => {
    dispatch({
      type: actions.SET_STATE,
      payload:{
        arcadeGameStep: 1,
        arcadeGameURL: theURL
      }
    })
  }

  const showcase = Object.entries(gameObject).map((key) => {
    console.log("checking showcase")
    console.log(key)
    
    return(
      <>
      <Col span={1} />
      <Col span={22}>
        <Card bordered={false} className="hp-text-center" style={{ width: "100%", cursor: "pointer" }} onClick={() => {openGame(key[1].gameurl)}}>
          <img src={key[1].gameIcon} style={{width: "100%"}} />
          <br />
          <h3>{key[1].gamename}</h3>
        </Card>
      </Col>
      <Col span={1} />
      </>
    )
  })
  return (
    <>
    <br />
    <Row align="middle" justify="space-between">
      <Col span={24} className="hp-text-center">
          <h3>ARCADE GAMES</h3>
      </Col>
    </Row>
    {arcadeGameStep === 0
    ?
      <Row align="middle" justify="space-between">
        {showcase}
      </Row>
    :
      <Iframe
        // url={`${gameUrl}?gameid=${gInndex}&userid=${id}&endpoint=${endpoint}&pointingsystem=${point}`}
        url={arcadeGameURL}
        height="100%!important"
        width="100%"
        frameBorder="0"
        styles={{border: "0 !important"}}
      />
    }
    
    </>
  );
}
          