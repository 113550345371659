import actions from './actions'
import { UnityContext } from "react-unity-webgl";

const initialState = {
  // scenes: {},
  // currentScene: '',
  order: [],
  imagePopOpen: false,
  imagePopURL: "",
  windowHeight: window.innerHeight,
  windowWidth: window.innerWidth,
  isAvatarCustomizerOpen: false,
  isInstructionsOpen: false,
  unityContext: UnityContext,
  realms: {},
  currentRealm: '',
  sendMessage: null,
  unityProvider: null,
  isLobbyCounter: 0,
  isSessionJoined: false,
  avatarNumber: null,
  showReconnectScreen: false,
  isInteractionDrawerVisible: false,
  interactionDrawerModule: "",
  isInteractionModalVisible: false,
  interactionModalModule: "",
  interactionIndex: "",
  arcadeGameStep: 0,
  arcadeGameURL: "",
  photoboothStep: 0,
  photoboothUrl: "",
  imageProcessing: false,
  currentbackground: 1
}

export default function virtualuserReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    // case actions.SET_DATA:
    //   console.log("inside SET DATA")
    //   console.log(action.payload)
    //   console.log(initialState.currentRealm)
    //   return {
    //     ...state,
    //     realms: {
    //       ...state.realms,
    //       [initialState.currentRealm]: {
    //         ...state.realms[initialState.currentRealm],
    //         [action.payload.currentEditorScreen]: {
    //           ...state.realms[initialState.currentRealm][action.payload.currentEditorScreen],
    //           data: action.payload.actualload,
    //         },
    //       }
    //     }
    //   }
    default:
      return state
  }
}