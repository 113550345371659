import { useSelector } from 'react-redux';

import { ConfigProvider } from 'antd';

import Router from "./router/Router";
import ImagePop from './view/common/components/ImagePop';
import AvatarCustomizer from 'view/common/components/AvatarCustomizer';
import RealmEditor from 'view/common/components/RealmEditor';
import InteractionDrawer from 'view/common/components/InteractionDrawer';
import InteractionModal from 'view/common/components/InteractionModal';

export default function App() {
  const customise = useSelector(state => state.customise)

  return (
    <ConfigProvider direction={customise.direction}>
      <Router />
      <ImagePop />
      <AvatarCustomizer />
      <RealmEditor />
      <InteractionDrawer />
      <InteractionModal />
    </ConfigProvider>
  );
}