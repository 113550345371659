import React, {useEffect, useState} from 'react';
import {
  Modal,
  // Row,
  // Col,
  // Button,
} from 'antd'
import Iframe from 'react-iframe'
import { CloseCircleTwoTone } from '@ant-design/icons'
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/altRealm/actions';
import "./avatar.less"

export default function AvatarCustomizer() {
  const requiredState = useSelector(({altRealm, virtualUser})=>({altRealm, virtualUser}))
  const dispatch = useDispatch();
  const {altRealm:{isAvatarCustomizerOpen}, virtualUser: {avatarDataLoaded}} = requiredState
  let theFrame = null
  useEffect(() => {
    const subscribeiFrame = (event) => {
      theFrame = theFrame === null ? document.getElementById("readyFrame") : null
      const json = parseJson(event);
  
      if (json?.source !== 'readyplayerme') {
        return;
      }
  
      // Susbribe to all events sent from Ready Player Me once frame is ready
      if (json.eventName === 'v1.frame.ready') {
        if(theFrame !== null)
        {
          theFrame.contentWindow.postMessage(
            JSON.stringify({
              target: 'readyplayerme',
              type: 'subscribe',
              eventName: 'v1.**'
            }),
            '*'
          );
        }

      }
  
      // Get avatar GLB URL
      if (json.eventName === 'v1.avatar.exported') {
        console.log(`Avatar URL: ${json.data.url}`);
        dispatch({
          type: actions.SEND_AVATAR,
          payload:{
            avatarURL: json.data.url,
            instance: "later"
          }
        })
        dispatch({
          type: actions.SET_STATE,
          payload: {
            isAvatarCustomizerOpen: false,
          },
        })

      }
  
      // Get user id
      if (json.eventName === 'v1.user.set') {
        console.log(`User with id ${json.data.id} set: ${JSON.stringify(json)}`);
      }
    }
  
    window.addEventListener('message', subscribeiFrame);
    document.addEventListener('message', subscribeiFrame);
  }, [isAvatarCustomizerOpen]);


  const closeModal  = () => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isAvatarCustomizerOpen: false,
      },
    })
  }

  const parseJson = (event) => {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  }


  const appname = "altyug"
  return (
    <Modal
      visible={isAvatarCustomizerOpen}
      wrapClassName="avatarModal"
      maskClosable={false}
      mask
      width="1280px"
      height="800px"
      centered
      onOk={closeModal}
      confirmLoading
      onCancel={closeModal}
      footer={null}
      closeIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} />}
      maskStyle={{ backgroundColor: 'rgba(27, 27, 27, 0.3)' }}
      destroyOnClose
    >
      <Iframe
        url={`https://${appname}.readyplayer.me/avatar?frameApi`}
        // ref={theFrame}
        id="readyFrame"
        width="100%"
        height="800px"
        loading="eager"
        frameBorder={0}
        className="theavatariFrame"
        display="initial"
        position="relative"
        allow="camera *; microphone *"
      />
    </Modal>
  );
}
          