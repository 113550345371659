import { Col, Tooltip, Row,Space, Button } from "antd";
import Altyug from "assets/images/logo/png/logo-dark.png";
import AvatarIcon from "assets/images/icons/avatar.png"
import HelpIcon from "assets/images/icons/help.png"
import {
  Edit
} from "react-iconly";
import "./interactions.less"
import { useDispatch } from "react-redux";
import Instructions from "view/common/components/Instructions";
import HeaderUser from "../header/HeaderUser";

export default function Interactions() {
  const dispatch = useDispatch();
  const openAvatarCustomizer = () => {
    dispatch({
      type: 'altRealm/SET_STATE',
      payload: {
        isAvatarCustomizerOpen: true
      }
    })
  }

  const openInstructions = () => {
    dispatch({
      type: 'altRealm/SET_STATE',
      payload: {
        isInstructionsOpen: true
      }
    })
  }

  const openEditor = () => {
    dispatch({
      type: 'realmEditor/SET_STATE',
      payload: {
        isRealmEditorOpen: true,
        currentStep: 0
      }
    })
  }

  return (
    <>
    <Instructions />
    <Row className="interactionLayerTop" align="middle" justify="space-between">
      <Col lg={2} md={2} sm={6} xs={8} className="logoArea">
        <img src={Altyug} alt="logo" style={{width: "100%"}} />
      </Col>
      <Col lg={20} md={20} sm={14} xs={12} />
      <Col lg={1} md={1} sm={4} xs={3} className="hp-text-center">
        <HeaderUser />
      </Col>
    </Row>

    <Row className="interactionLayerBottom" align="middle" justify="space-between">
      <Col lg={2} md={2} sm={6} xs={6} />
      <Col lg={16} md={16} sm={12} xs={8} className="hp-text-center">
        <Button type="primary" shape="circle" onClick={openEditor} icon={<Edit set="curved" className="remix-icon" />} />
      </Col>
      <Col lg={2} md={2} sm={6} xs={6}  className="rightPanel">
        <Space size="large">
          <Tooltip title="Change Avatar">
            <img src={AvatarIcon} alt="logo" onClick={openAvatarCustomizer} style={{height: "35px"}} />
          </Tooltip>
          <Tooltip title="View Controls">
            <img src={HelpIcon} onClick={openInstructions} alt="logo" style={{height: "30px"}} />
          </Tooltip>
        </Space>
      </Col>
    </Row>
    </>
    
  );
};