import React from 'react';
import {
  Modal,
  Row,
  Col,
  Button,
} from 'antd'
import { CloseCircleTwoTone } from '@ant-design/icons'
import MoveControl from "assets/images/icons/move.png"
import KeysControl from "assets/images/icons/keys.png"
import ShiftControl from "assets/images/icons/shift.png"
import SpacebarControl from "assets/images/icons/spacebar.png"
import { useDispatch, useSelector } from "react-redux";
import actions from 'redux/virtual/altRealm/actions';
import "./instructions.less"

export default function Instructions() {
  const requiredState = useSelector(({altRealm })=>({altRealm}))
  const dispatch = useDispatch();
  const {altRealm:{isInstructionsOpen}} = requiredState


  const closeModal  = () => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isInstructionsOpen: false,
      },
    })
  }


  const appname = "altyug"
  return (
    <Modal
      visible={isInstructionsOpen}
      wrapClassName="instructionsModal"
      maskClosable
      mask
      width="40vw"
      height="15vh"
      centered
      onOk={closeModal}
      confirmLoading
      onCancel={closeModal}
      footer={null}
      closeIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} />}
      maskStyle={{ backgroundColor: 'rgba(27, 27, 27, 0.3)' }}
      destroyOnClose
    >
      <Row>
        <Col span={24} className="hp-text-center">
          <h2 style={{color: "black"}}>Controls</h2>
        </Col>
      </Row>
      <Row>
        <Col span={9} />
        <Col span={6} className="hp-text-center">
          <img src={MoveControl} style={{ width: "100%"}} />
          <br />
          Use Mouse to look around
        </Col>
        <Col span={9} />
      </Row>
      <br />
      <br />
      <Row>
        <Col span={1} />
        <Col span={6} className="hp-text-center">
          <img src={KeysControl} style={{ width: "100%"}} />
          <br />
          Use keys WASD to Move
        </Col>
        <Col span={2} />
        <Col span={6} className="hp-text-center">
          <img src={ShiftControl} style={{ width: "100%"}} />
          <br />
          Hold Shift with WASD keys to run
        </Col>
        <Col span={2} />
        <Col span={6} className="hp-text-center">
          <img src={SpacebarControl} style={{ width: "100%"}} />
          <br />
          Use Spacebar to Jump
        </Col>
        <Col span={1} />
      </Row>
      
    </Modal>
  );
}
          