import API from '../../configs/apiConfig'

export async function updateRealmScene(data) {
    console.log('Update')
    console.log(data)
    return API.post(`updateRealmSceneData?scene_id=${data.realmID}`, data.categories, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }


  export async function getSceneSavedData() {
    return API.get('getRealmScene')
}

