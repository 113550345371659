import React from "react";
import {Row,Col,Card} from 'antd'
import { useDispatch, useSelector } from "react-redux";
// import actions from 'redux/virtual/altRealm/actions';

export default function ImageView() {
  const requiredState = useSelector(({altRealm})=>({altRealm}))
  const {altRealm:{realms, currentRealm,interactionIndex}} = requiredState
  const theChosenRealm = realms[currentRealm]
  let theChosenProduct = ""
  if(interactionIndex !== "")
  {
    if(typeof theChosenRealm.images.data[interactionIndex] !== 'undefined') {
      theChosenProduct = theChosenRealm.images.data[interactionIndex]
    }
  }
  return (
    <>
      <Row>
        <Col span={15}>
          <img src={theChosenProduct.url} style={{width: "100%", borderRadius: "20px"}} alt={theChosenProduct.name} />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <br />
          <br />
          <Row>
            <Col span={24}>
              <h3 style={{fontWeight: "800"}}>{theChosenProduct.name}</h3>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
            <div style={{color: "white"}} dangerouslySetInnerHTML={{__html: theChosenProduct.description}} />
              {/* <p style={{color: "white"}}>{theChosenProduct.description}</p> */}
            </Col>
          </Row>
        </Col>
        <Col span={1} />
      </Row>
    </>
  );
}
          