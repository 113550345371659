import { all } from 'redux-saga/effects'
import user from './virtual/user/sagas'
import registerVirtualUser from "./virtual/registerUser/sagas"
import altRealm from "./virtual/altRealm/sagas"
import realmEditor from "./virtual/realmEditor/sagas"
import gameContext from "./virtual/gameContext/sagas"

import organizer from './organizer/theorganizer/sagas'
import registerOrganizer from './organizer/registerOrganizer/sagas'
import eventSetup from './organizer/eventSetup/sagas'

import theAdmin from './admin/theAdmin/sagas'

import menu from './common/menu/sagas'
import commonsettings from './common/settings/sagas'
import organizerSetup from './organizer/agenda/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    registerVirtualUser(),
    altRealm(),
    realmEditor(),
    gameContext(),
    organizer(),
    registerOrganizer(),
    eventSetup(),
    theAdmin(),
    menu(),
    commonsettings(),
    organizerSetup()
  ])
}
